@include media(lg) {
    .sub-footer {
        &__wrapper {
            justify-content: space-between;
            flex-direction: row;
            text-align: right;
        }

        &__customer-switch {
            text-align: left;
        }

        &__links {
            text-align: right;
            margin: 0;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1398";