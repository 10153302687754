.list-item {
    display: flex;
    padding-left: $space * 3;
    align-items: center;
    position: relative;
    height: $space * 8;

    &[class*="icon--"]::before,
    &[class*="fa-"]::before {
        display: flex;
        width: $space * 8;
        color: $grey--dark;
        align-items: center;
        justify-content: center;
    }

    &[class*="icon--"]::before {
        font-size: $font-size--medium-plus;
    }

    &[class*="fa-"]::before {
        font-family: FontAwesome;
        font-size: $font-size--medium;
    }

    &::after {
        @include border(bottom, $color: $grey--light);
        bottom: 0;
        content: "";
        left: 15px;
        position: absolute;
        right: 0;
        z-index: $z1;
    }

    &--large {
        height: 48px;
        padding: $space * 2;
    }

    &.active {
        &[class*="icon--"]::before,
        &[class*="fa-"]::before {
            color: $theme-color;
        }
    }

    &__link {
        @include input(hoverable) {
            &:hover {
                color: $theme-color;
                text-decoration: none;
            }
        }

        flex: auto;
        margin-right: $space * 6;
        display: inline-block;
        align-items: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: $grey--darker;
    }

    &__image {
        margin-left: 16px;
        margin-right: 12px;
    }

    &--with-icon {
        &::after {
            left: 40px;
        }

        padding-left: 0;
    }

    &--with-image {
        &::after {
            left: 62px;
        }

        padding: 0;
        height: $space * 8;
    }

    &--bold {
        .list-item__link {
            font-weight: $font-weight--bold;
        }
    }

    &--title {
        padding-left: $space * 3;
    }

    &--has-children {
        .list-item__link::after {
            @include icon($fa-var-chevron-right);

            position: absolute;
            right: 12px;
            bottom: 12px;
            font-size: $font-size--medium;
            color: $grey--dark;

            @include media(xl) {
                display: none;
            }
        }

        @include input(hoverable) {
            &:hover .list-item__link {
                color: $theme-color;
            }
        }
        &:hover,
        &.active {
            .list-item__link::after {
                display: block;
            }
        }
    }

    &--more[class*="fa-"]::before {
        color: $theme-color;
        margin-left: 13px;
        margin-right: 10px;
    }

    &--separator-dark::after {
        border-color: $grey;
    }

    &--separator-full::after {
        left: 0;
    }

    &--reverse {
        &[class*="fa-"]::before {
            color: $grey;
        }

        background-color: $grey--lighter;

        .list-item__link {
            &::after {
                content: '';
            }

        }

    }
}

;@import "sass-embedded-legacy-load-done:761";