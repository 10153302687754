.modal-form-message {
    .panel {
        max-width: 650px;
    }

    .panel__headline {
        color: #fff;
        font-weight: $font-weight--bold;
    }

    .panel__body {
        display: flex;
    }

    &__product-image {
        height: 80px;
        width: 80px;
    }

    &__product-image-wrapper {
        min-width: 80px;
    }

    &__product-price {
        font-family: $font-family--main;
        color: $theme-color;
        font-size: $font-size--xlarge;
        line-height: $line-height--large-plus;
    }

    &__product-price-tax-rate {
        color: $grey--dark;
        margin-bottom: $space * 3;
    }

    &__action-buttons {
        display: flex;
        flex-direction: column;

        .button {
            flex: 1 1 auto;
            margin-bottom: $space;
        }
    }

    &__delivery-time {
        min-height: 20px;
    }
}

@include media(lg) {
    .modal-form-message {
        .panel__body {
            align-items: center;
        }

        &__product-image {
            width: 100px;
            height: 100px;
        }

        &__product-image-wrapper {
            min-width: 100px;
        }

        &__action-buttons-wrapper {
            display: flex;
            width: 100%;
        }

        &__action-buttons {
            flex-direction: row;

            .button {
                margin: 0 0 0 ($space * 2);

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        &__delivery-time {
            min-height: 30px;
        }
    }
}

;@import "sass-embedded-legacy-load-done:885";