.o-item-bottom-action {
    margin: ($space * 4) 0 0 ($space * 4);

    &__btn-text {
        font-size: $font-size--regular-plus;
        line-height: $line-height--regular;
        margin-left: $space;
    }

    &__btn {
        height: $space * 4;
        padding: 0;
        & [class^=icon--] {
            width: $space * 4;
            height: $space * 4;
            font-size: $font-size--regular-plus;
        }
    }
}

;@import "sass-embedded-legacy-load-done:813";