.a-video-thumbnail {
    position: relative;
    overflow: hidden;
    height: 90px;
    width: 110px;
    border-radius: $border-radius;

    &--large {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        height: auto;
        width: 100%;
    }

    &--large img {
        width: 100%;
    }

    &__icon,
    &__icon--large,
    img {
        position: absolute;
        top: 0;
        left: 0;
    }

    &__icon,
    &__icon--large {
        color: white;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.4);
    }

    &__icon {
        font-size: $font-size--xlarge + 10;
    }

    &__icon--large {
        font-size: $font-size--xlarge + 30;
    }

    & img {
        object-fit: cover;
        height: 90px;
        width: 110px;
    }
}

;@import "sass-embedded-legacy-load-done:1163";