.m-product-delivery {
    display: flex;

    &__icon {
        margin-right: $space * 2;
        padding: 2px 0;
        width: 18px;
        height: 18px;
    }

    &__text-wrap > span {
        text-wrap: wrap;
    }

    &__express-info {
        vertical-align: text-bottom;
        margin-left: $space * 2;
    }
}

;@import "sass-embedded-legacy-load-done:1208";