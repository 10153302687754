.ca-widget-wrapper {
    display: flex;
    flex: auto;
    min-height: 200px;
    position: relative;

    &.loading {
        display: block;
        width: 100%;
    }

    .fa-spin {
        color: $theme-color;
        font-size: 30px;
        left: calc(50% - 21px);
        position: absolute;
        top: calc(50% - 21px);
    }

    .ca-widget {
        animation-duration: 0.7s;
        animation-name: fadeIn;
    }
}

.ca-widget {
    flex: auto;
    background-color: #fff;
    border-radius: $border-radius;
    box-shadow: $shadow6;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &__heading {
        @include border('bottom');
    }

    &__icon {
        font-size: $font-size--large;
        position: absolute;
        right: $space * 4;
        top: 50%;
        transform: translateY(-50%);
    }

    &__heading-link {
        display: block;
        font-size: $font-size--medium;
        font-weight: $font-weight--bold;
        padding: $space * 3;
        position: relative;

        &--secondary {
            color: $grey--dark;

            &:hover {
                text-decoration: none;
                color: $theme-color;
            }
        }

        &--primary {
            background-color: $blue;
            border: 0;
            color: #fff;

            &:hover {
                text-decoration: none;
            }
        }
    }

    p {
        height: 100px;
        padding: $space * 3;
    }

    &.orders th:last-child {
        min-width: 100px;
    }

    &__footer {
        align-items: center;
        box-shadow: 0 2px 8px 0 rgba(#000, 0.5);
        display: flex;
        flex-wrap: wrap;
        height: 50px;
        margin-top: auto;

        a {
            display: block;
            font-weight: $font-weight--bold;
            height: 100%;
            padding: $space * 3;
            position: relative;
            text-align: left;
            width: 100%;

            &:hover {
                text-decoration: none;
            }

            &:hover i {
                color: $theme-color;
            }
        }

        p {
            flex-grow: 1;
        }

        i {
            color: $grey--dark;
            font-size: 20px;
            font-weight: $font-weight--bold;
            position: absolute;
            right: $space * 3;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

;@import "sass-embedded-legacy-load-done:687";