@include media(xxl) {
    .o-product-list {
        &__content {
            &.-is-empty {
                margin: 4 * $space 14 * $space;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:1216";