@include media(lg) {
    .t-cart-v2-item {
        &__info-and-price {
            display: flex;
            flex-grow: 1;
            padding-left: 0;
        }

        &__image {
            min-width: 170px;
            width: 170px;
            height: 170px;

            .cart__img {
                width: 100%;
            }
        }

        &__info {
            flex-grow: 1;
            padding-left: $space * 4;
            padding-right: $space * 4;
        }

        &__delivery {
            margin-top: $space * 4;
            margin-bottom: $space * 4;
        }

        &__quantity-price {
            display: flex;
            flex-direction: column;
            min-width: 150px;
            width: 150px;
            padding-left: 0;
            margin-top: 0;
        }

        &__bottom-links {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            background-color: white;
        }
    }
}

;@import "sass-embedded-legacy-load-done:857";