select {
    width: 100%;
    height: 40px;
    padding: 10px 32px 10px 8px;
    border-radius: $border-radius;
    appearance: none;
    line-height: 1em;
    cursor: pointer;
    background: #fff url(../../../../images/sprite-inputs.png) no-repeat scroll calc(100% - 10px) -10px;
    background-size: 15px;
    @include border;

    &::-ms-expand { display: none; }

    &:focus {
        outline: none;
        background-color: $grey--light;
        border-color: $theme-color;
    }

    &[disabled],
    &.disabled {
        cursor: not-allowed;
        background: #fff url(../../../../images/sprite-inputs.png) no-repeat scroll calc(100% - 10px) 16px;
        background-size: 15px;
        padding: ($space * 2) 32px ($space * 2) 8px;
    }
}

.select-disabled {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(#fff, 0.5);
        cursor: not-allowed;
    }
}

;@import "sass-embedded-legacy-load-done:771";