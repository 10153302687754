@include media(xxl) {
    .o-campaign-card {
        grid-template-columns: 1fr 1fr;
        column-gap: 14 * $space;
        margin-bottom: 20 * $space;

        &__image-container {
            grid-row: 1;
            grid-column: 2;
        }

        &__image {
            aspect-ratio: 3 / 2;
        }

        &__details {
            grid-row: 1;
            grid-column: 1;
        }

        &__cta {
            margin-top: 14 * $space;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1066";