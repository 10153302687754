.o-product-overlay {
    background: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;

    &__video--wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__image {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        position: relative;
    }

    &__nav-left {
        left: 0;
        justify-content: flex-end;
    }

    &__nav-right {
        right: 0;
        justify-content: flex-start;
    }

    &__nav-left,
    &__nav-right {
        position: absolute;
        font-size: $font-size--xlarge;
        border: none;
        color: $grey--medium;
        background: transparent;
        min-height: 100px;
        top: 0;
        display: flex;
        align-items: center;
        height: 100%;
        width: calc((100% - #{$image-size-xxl}) / 2);
    }

    &__thumb-wrapper {
        background: $grey--darkest;
        width: 100%;
        display: flex;
    }

    &__thumb-container {
        display: flex;
        flex-direction: row;
        margin: auto;
        z-index: $z3;
        overflow: auto;
        scroll-behavior: smooth;
    }

    &__close-button--wrapper {
        display: flex;
        justify-content: flex-end;
        width: 106px;
        position: absolute;
        top: $space * 4;
        right: 0;
    }

    &__close-button {
        height: 66px;
        width: 66px;
        border: 0;
        padding: $space * 3;
        font-size: $font-size--large;
        background: $grey--light;
    }

    &__counter {
        position: absolute;
        top: 43px;
        right: 105px;
    }

    &__thumb--image {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-width: 80px;
        min-height: 80px;
    }

    &__nav-inner {
        width: unset;
    }

    &__bundle {
        width: 700px;
        height: 700px;
        margin: auto;

        &-thumb {
            background-color: white;
            width: 80px;
            height: 80px;
        }
    }
}


;@import "sass-embedded-legacy-load-done:1306";