.o-add-to-cart-modal {

    &__related-products {
        @include border($where: 'top');
        background-color: white;
        margin-top: $space * 2;
        padding: 0;

        &-title {
            padding: $space * 3;
        }
        &-item {
            min-width: 200px;
            position: relative;
        }

        &-item-badges {
            left: 0;
            position: absolute;
            top: 0;
        }
    }

    &__product {
        padding: $space * 3;
    }

    &__product-quantity {
        margin-bottom: $space * 2;
    }
    &__product-action-buttons-wrapper {
        display: flex;
        margin-top: $space *2;
        width: 100%;
    }
    &__product-action-buttons {
        display: flex;
        flex-direction: column;
        width: 100%;

        .button {
            flex: 1 1 auto;
            margin-bottom: $space;
        }
    }
    &__product-details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

@include media(lg) {
    .o-add-to-cart-modal {
        &__related-products {
            margin-top: 0;

            &-item {
                min-width: unset;
            }
        }

        &__product-action-buttons {
            flex-direction: row;

            .button {
                margin: 0 0 0 ($space * 2);

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:1242";