/* ------------------------------------ *\
    FOOTER
\* ------------------------------------ */

.footer {
    @include media(lg) { margin-top: $space * 12; }

    [data-target] {

        & > .fa {
            position: absolute;
            right: $space * 2;
        }

        &.collapsed > .fa::before {
            content: $fa-var-angle-down;
            @include media(lg) { content: ""; }
        }
    }

    [data-collapsible] {
        max-height: 100%;

        &.collapsed {
            overflow: hidden;
            max-height: 0;
            border: 0;
            padding: 0 !important; //override helpers

            @include media(lg) {
                overflow: auto;
                max-height: 100%;
            }
        }

        & > li {
            position: relative;
            @include border($where: 'bottom');
            @include media(lg) { border: 0; }
        }

        & > li:last-child { border: 0; }

        & > li::before {
            content: $fa-var-angle-right;
            position: absolute;
            right: 2px;
            font-family: $font-family--font-awesome;
            font-size: $font-size--large;
            @include media(lg) { content: ""; }
        }
    }
}

.footer__nl--sales {
    text-align: center;
    @include media(lg) { text-align: left; }
}

.footer__nl__image--sales {
    position: absolute;
    left: 50%;
    transform: translate3d(-50%, 0, 0);

    @include media(lg) {
        bottom: -($space * 10) + 2;
        width: 180px;
        height: 108px;
    }

    @include media(xl) {
        width: 200px;
        height: 120px;
        bottom: -($space * 8);
    }
}

.footer__svg-list > a {
    padding: 0;
    width: 55px;
}

.footer__img-link {
    width: 32px;

    &:hover { opacity: 0.7; }

    &.fa {
        font-size: 18px;
        padding: 7px 5px;
        &:hover { text-decoration: none; }
    }

    &.fa-facebook {
        padding-right: 7px;
        background-color: $blue--facebook;
    }

    &.fa-instagram { background-color: $pink--instagram; }

    &.fa-linkedin-square { background-color: $blue--linkedin; }

    &.fa-youtube-play { background-color: $red--youtube; }

    img {
        height: 32px;
    }
}

.footer__customer-phone {

    & > br,
    & > strong { display: none; }

    a { color: $grey--darker; }
}

.footer--sticky {
    .alert--error {
        margin-bottom: ($space * 3);
    }
}

.footer--sticky--holiday {
    background-image: url(../../../images/holiday-campaign.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    height: 150px;

    h1 {
        font-family: $font-family--main;
        font-size: 42px;
        line-height: 0.8;
    }

    h3 {
        font-weight: $font-weight--bold;
    }

    .button--primary {
        margin-top: $space * 2;
        padding: $space;
        width: 100%;

        &:hover {
            text-decoration: none;
        }

        .fa {
            font-size: 18px;
            margin-left: 10px;
            margin-top: 5px;
        }
    }

    .button__text {
        font-size: 20px;
    }

    .footer--sticky__button > .fa {
        color: #fff;
    }
}

.footer--sticky.stuck {
    @include media(lg) {
        position: fixed;
        bottom: -300px;
        z-index: $z5;
        transition: bottom 0.5s ease;
        &.visible { bottom: 0; }
        .footer--sticky__button { display: block; }
    }

    .tooltip {
        top: -30px;
        width: 290px;

        &::before,
        &::after { display: none; }
    }
}

.footer--sticky__button.button {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    & > .fa { font-size: $font-size--medium; }
}

.footer--sticky__caption {
    margin-top: $space * 2;

    @include media(xl) {
        line-height: 1.1em;
        margin-top: -2px;
    }
}


@include media(lg) {
    .footer--sticky--holiday {
        h1 {
            font-size: 50px;
            margin-top: $space * 4;
        }

        .button--primary {
            margin: ($space * 11 )  0 ($space * 3);
            padding: 0 ($space * 2);
            width: initial;
        }

    }
}

@include media(xl) {
    .footer--sticky--holiday {
        .button--primary {
            margin: ($space * 12) 0 ($space * 2) ($space * 2);
            padding: 10px;
        }

        h1 {
            margin-top: 0;
            font-size: 62px;
        }
    }
}

;@import "sass-embedded-legacy-load-done:744";