@include media(xxl) {
    .o-column-block__container.-many {
        flex-wrap: unset;

        .o-column-block {
            width: 100%;

            &:not(:last-child) {
                margin-right: $space * 8;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:1090";