//Refactors: styles/v2/components/_quantity-widget.scss

/* ----------------------------------------------- *\
    QUANTITY WIDGET LG (when the button is present)
\* ----------------------------------------------- */
@include media(lg) {
    .m-quantity-widget {
        &__button--buy {
            max-width: 55px;

            [class^="icon--"] {
                font-size: 20px;
            }
        }
    }
}

/* ------------------------------------ *\
    QUANTITY FIELD (+/-)
\* ------------------------------------ */
@include media(lg) {
    .m-quantity-field {
        width: 120px;

        &__input {
            width: calc(100% - 70px);
        }
        &__button {
            padding: 8px 0;
            min-width: 35px;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1024";