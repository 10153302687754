@mixin flex {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@mixin ellipsis ($width) {
    @include truncate-text;
    word-wrap: break-word;
    max-width: $width;
}

.o-eye-catcher {
    &--full-blue,
    &--half-blue {
        @include flex;
        color: #fff;
        text-align: center;
        background-repeat: no-repeat;
        background-size: 100%;
        width: 95px;
        height: 88px;
    }
    &--full-blue {
        padding: ($space * 2) ($space * 2);
        background-image: url(https://media.contorion.de/content/eyecatcher/eyecatcher-full-blue-small.png);
    }

    &--half-blue {
        padding: ($space * 2) 0;
        background-image: url(https://media.contorion.de/content/eyecatcher/eyecatcher-half-blue-small.png);
        justify-content: flex-start;

        > [class*='sec-text'],
        [class*='action-text'] {
            color: $blue;
        }
    }
    &__upper-half {
        @include flex;
        &--discount_code {
            padding-bottom: 7px;
        }
        &--discount_code_brand {
            justify-content: flex-start;
            padding-bottom: $space;
        }
    }
    &__primary-text-container {
        > * {
            display: inline-block;
        }
        display: flex;
        align-items: baseline;
    }

    &__primary-text {
        &--text {
            @include multi-line-ellipsis (90px, 3);
        }
    }
    &__asterisk {
        align-self: flex-start;
    }
    &__primary-sub-text {
        @include ellipsis (85px);
        &--discount_code {
            display: none;
        }
    }
    &__action-text-container {
        cursor: copy;
    }
    // follows the new typography
    &__discount-text,
    &__action-text {
        font-size: $font-size--regular-plus;
        line-height: $line-height--regular;
    }
}

;@import "sass-embedded-legacy-load-done:1347";