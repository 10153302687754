@include media(lg) {
    .o-product-list {
        &__content {
            &.-is-empty {
                margin: 4 * $space 28 * $space 8 * $space;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:1214";