//TODO: There exist no mock-up for xl from UX. lg mock up (960px resolution) is for both lg & xl.
//We would need to evaluate if the styles in this sheet are belonging to either lg or xxl stylesheets.

@include media(xl) {
    .hero-banner {
        background-position: center;

        &__content--bg {
            background-color: transparent;
        }

        &__full-width {
            margin-bottom: $space * 20;

            .brandpage__banner {
                aspect-ratio: 2 / 1;
                background: top/cover no-repeat;
                height: 480px;
                width: 100%;
            }

            .brandpage__slim-banner {
                aspect-ratio: 7 / 1;
            }
        }

        &__content--container {
            position: absolute;
            padding: 0;
            max-width: 900px;
            top: 65%;
            transform: translate(0, -65%);
            text-align: left;
        }

        &__btn--container {
            flex-direction: row;
            align-items: normal;
        }

        &__btn {
            width: 230px;
            margin-right: $space * 2;
        }
    }
}

;@import "sass-embedded-legacy-load-done:996";