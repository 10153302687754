.steps-accordion {
    $root: &;

    &__header,
    &__footer {
        margin: ($space * 3) 0;
    }

    &__header {
        display: flex;
        align-items: center;
    }

    &__indicator {
        display: inline-block;
        align-items: center;
        justify-content: center;
        width: $space * 6;
        height: $space * 6;
        margin-right: $space * 2;
        border-radius: 50%;
        padding: $space 0 0 $space * 2;
        color: #fff;
        font-size: $font-size--medium;
        font-weight: $font-weight--bold;

        &.fa {
            padding: 2px 0 0 8px;
            font-size: $font-size--regular-plus;
            font-weight: $font-weight--base;
            line-height: $line-height--large;
        }

        &-number {
            background-color: $grey;
        }

        &-check {
            display: none;
            color: $green;
            background-color: $green;
        }
    }

    &__edit-button {
        display: none;
        float: right;
        font-weight: $font-weight--bold;
    }

    &__body {
        &--no-shadow {
            box-shadow: none;
        }

        &--background {
            background-color: white;
        }

        box-shadow: $shadow6;
        border-radius: $border-radius;
    }

    &__card {
        background-color: white;
        padding: $space * 2;
        box-shadow: $panel-shadow;
        border-radius: $border-radius;
    }

    &__customer-address {
        .panel {
            box-shadow: $panel-shadow !important;
            border-radius: $border-radius;
            padding: $space * 3;
        }

        .panel__header,
        .panel__body {
            padding: 0;
        }

        .steps-accordion__billing-address {
            &-title {
                margin-bottom: $space * 3;
            }

            &-checkbox {
                border-bottom: 1px solid $grey;
            }
        }
    }

    &__billing-address {
        &-title {
            margin-bottom: $space * 3;
        }
    }

    &__body,
    &__footer,
    &__content,
    &__summary {
        display: none;
    }

    &__content {
        .panel {
            box-shadow: unset;
        }

        .panel__header {
            border-bottom: 1px solid $grey;
        }
    }

    &--completed &__body,
    &--editing &__body,
    &--editing &__footer,
    &--editing &__content {
        display: block;
    }

    &--editing &__indicator-number {
        background-color: $blue;
    }

    &--completed:not(&--editing) {
        #{$root}__edit-button {
            display: inline-block;
        }

        #{$root}__indicator-number {
            display: none;
        }

        #{$root}__content {
            display: none;
        }

        #{$root}__summary {
            display: block;
            animation: fadeIn 1s;
            background-color: white;
            padding: $space * 2;
        }

        #{$root}__indicator-check {
            display: inline-block;
            animation: fadeIn 0.2s, changeColor 0.3s ease 0.2s both running;
        }
    }

    &__edit-button {
        margin-left: auto;
        margin-right: 0;
    }

    &__extra-form {
        padding: 0 $space *2;
        background: white;
    }

    @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
    }

    @keyframes changeColor {
        from { color: $green; }
        to { color: white; }
    }
}

;@import "sass-embedded-legacy-load-done:905";