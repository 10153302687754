.annotation {
    display: flex;

    &::before {
        display: inline-block;
        min-width: 18px;
        max-width: 18px;
        font-weight: $font-weight--bold;
        text-align: right;
        margin-right: 5px;
    }

    &--two::before {
        min-width: 10px;
        max-width: 10px;
    }

    &--first::before {
        content: "*";
    }

    &--second::before {
        content: "**";
    }

    &--third::before {
        content: "***";
    }
}

;@import "sass-embedded-legacy-load-done:193";