.m-product-rating {
    display: flex;
    flex-direction: row;

    &--small {
        gap: $space;
        height: $font-size--small;
    }

    &--medium {
        gap: 2 * $space;
        height: $font-size--medium;
    }

    &--large {
        gap: 2 * $space;
        height: $font-size--large;
    }

    &__stars {
        display: flex;
        flex-direction: row;

        &--small {
            gap: 2px;
            font-size: $font-size--small;
        }

        &--medium {
            gap: 3px;
            font-size: $font-size--medium;
        }

        &--large {
            gap: $space;
            font-size: $font-size--large;
        }
    }

    &__full-star {
        color: $yellow;
    }

    &__incomplete-star {
        position: relative;
    }

    &__background-star,
    &__empty-star {
        color: $grey;
    }

    &__partial-star {
        position: absolute;
        top: 0;
        left: 0;
        color: $yellow;
    }

    &__label--medium {
        margin: 1px 0;
    }
}

;@import "sass-embedded-legacy-load-done:1018";