.range-slider {
    &__slider {
        position: relative;
        height: $space * 4;
        top: $space * 2;
    }

    &__bar {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 8px;
        border-radius: $border-radius;
        background: $grey--medium;

        &--selected {
            background-color: $blue;
        }
    }

    &__handle {
        position: absolute;
        z-index: $z5;
        height: $space * 4;
        width: $space * 4;
        top: -40%;
        border-radius: percentage(50);
        background-color: white;
        box-shadow: $shadow7;

        &:nth-of-type(1) {
            left: 0;
        }

        &:nth-of-type(2) {
            right: 0;
        }
    }

    &__controls {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 18px;
        input {
            max-width: 60px;
        }
    }

    &__input {
        display: flex;
        align-items: center;
    }

    &__delimiter {
        font-size: 24px;
        line-height: 24px;
        padding-left: $space * 4;
        padding-right: $space * 4;
        font-weight: $font-weight--light;
    }
}

;@import "sass-embedded-legacy-load-done:897";