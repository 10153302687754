@mixin animFade($name, $fadeFrom: 0, $fadeTo: 1, $hideAfterLastFrame: false) {
    @keyframes #{$name} {
        0% {
            opacity: $fadeFrom;
        }
        //99% {
        //    opacity: $fadeTo;
        //}
        100% {
            opacity: $fadeTo;
            @if ($hideAfterLastFrame) {
                visibility: hidden;
            }
        }
    }
}

@mixin animFadeAndTranslate($name, $translateFrom: -100, $translateTo: 0, $fadeFrom: 0, $fadeTo: 1, $hideAfterLastFrame: false) {
    @keyframes #{$name} {
        0% {
            opacity: $fadeFrom;
            transform: translateY(#{$translateFrom}px);
        }
        //99% {
        //    opacity: $fadeTo;
        //    transform: translateY(#{$translateTo}px);
        //}
        100% {
            opacity: $fadeTo;
            transform: translateY(#{$translateTo}px);
            @if ($hideAfterLastFrame) {
                visibility: hidden;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:64";