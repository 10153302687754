.js {
    [data-toggle-step] {
        display: none;
    }

    [data-toggle-step].active,
    [data-toggle-step].active.hidden {
        display: block !important;
        visibility: visible !important;
    }

    [data-toggle-step="2"].active.flex,
    [data-toggle-step="2"].active.hidden.flex {
        display: flex !important;
    }
}

;@import "sass-embedded-legacy-load-done:284";