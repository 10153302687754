$margin-top: 20 * $space;
$padding-left: 3 * $space;

@include media(lg) {
    .t-pdp {
        &__above-the-fold-container {
            flex-direction: row;
        }

        &__product-gallery {
            width: 65%;
            position: sticky;
            top: 0;
            max-height: 628px;
        }

        &__buy-box {
            width: 35%;
            padding-left: $padding-left;
        }

        &__bought-together,
        &__rich-content,
        &__usps {
            display: flex;
            background-color: $grey--darker;
            margin-top: $margin-top;
            @include grow-outside-container;
        }

        &__recommendations {
            margin-top: $margin-top;
        }

        &__description {
            .t-panel__body {
                h2 {
                    font-size: $font-size--medium-plus;
                    font-weight: $font-weight--bold;
                }
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:1371";