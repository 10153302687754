@include media(lg) {
    .o-expandable-product-list {
        padding: 0;
        &__products {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }

        &__product-container:nth-child(2n + 7) {
            display: none;
        }

        &__product-container.-expanded:nth-child(2n + 7) {
            display: block;
        }

        &__cta.-show-more {
            margin-top: $space * 6;
            padding-bottom: $space * 14;
            margin-bottom: $space * 14;
        }

        &__cta.-show-all {
            margin-top: $space * 6;
            padding-top: $space * 14;
            margin-bottom: $space * 14;
        }

    }
}

;@import "sass-embedded-legacy-load-done:1145";