.progress {
    border-radius: 3px;
    max-width: 350px;
    position: relative;

    &__bar {
        animation: 0.1s 4s visibilityFallback forwards;
        background: $green;
        border-radius: 3px;
        height: $space + 3;
        text-align: right;
        visibility: hidden;
    }

    &__text {
        color: black;
        padding-bottom: $space;
    }
}

@keyframes visibilityFallback {
    0% {
        visibility: hidden;
    }
    100% {
        visibility: visible;
    }
}

;@import "sass-embedded-legacy-load-done:520";