.o-featured-categories {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(2, 1fr);
    gap: 2 * $space;
    justify-content: stretch;
    justify-items: stretch;
    align-content: stretch;
    align-items: start;
}

;@import "sass-embedded-legacy-load-done:1068";