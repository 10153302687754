.m-product-list-item {
    padding: 4 * $space 0;
    display: grid;
    column-gap: 2 * $space;
    grid-template-columns: 80px 1fr 40px;
    @include border($where: 'bottom', $width: 1px, $color: $grey--light);

    &:last-of-type {
        border: none;
    }

    &__image {
        //Todo: Needs to be handled at missing image atom -> needs design specs
        width: 80px;
        height: 80px;
    }
    &__price {
        white-space: nowrap;
    }
    &__details {
        display: grid;
        row-gap: 3 * $space;
        width: 100%;
    }
    &__description {
        display: grid;
        row-gap: 2 * $space;
    }
}

;@import "sass-embedded-legacy-load-done:1194";