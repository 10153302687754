.a-badge-deprecated {
    background-color: $grey--dark;
    border-radius: $border-radius;
    color: #fff;
    display: inline-block;
    font-size: $font-size--small;
    font-weight: $font-weight--bold;
    padding: 4px 6px;
    white-space: nowrap;
    line-height: $line-height--small;

    small {
        font-weight: $font-weight--base;
    }

    &--primary {
        background-color: $theme-color;
    }

    &--grey {
        background-color: $grey--light;
        color: $grey--dark;
    }

    &--green {
        background-color: $green;

        &-inverted {
            background-color: white;
            @include border;
            border-color: $green;
            color: $green;
        }
    }

    &--dark-grey {
        background-color: $grey--dark;

        &-inverted {
            @include border;
            background-color: #fff;
            color: $grey--dark;
        }
    }

    &--yellow {
        background-color: $yellow;

        &-inverted {
            @include border;
            background-color: white;
            border-color: $yellow;
            color: $yellow;
        }
    }

    &--red {
        background: $red;

        &-inverted {
            @include border;
            background-color: white;
            border-color: $red;
            color: $red;
        }
    }

    &--voucher {
        background: $red;
        line-height: 1em;
        padding: 1px 6px;
    }

    &__icon {
        font-size: 8px;
        line-height: 10px;
    }
}

;@import "sass-embedded-legacy-load-done:960";