form .bpy {
    .form-control {
        border-radius: 2px;
        box-shadow: none;
        height: auto;
        line-height: 1em;
    }

    .form-group {
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: 5px;
    }

    .input-row .control-label {
        padding-left: 0 !important;
        padding-right: 0 !important;

        + [class^=col-] {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }

    .footer-row,
    .terms-of-service-row,
    .form-headline,
    .header-row {
        display: none;
    }

    .alert {
        margin-top: 0 !important;
    }

    .form-control-feedback.glyphicon.glyphicon-remove,
    .form-control-feedback.glyphicon.glyphicon-ok {
        display: none !important;
    }

    .has-error,
    .has-success {
        input,
        textarea {
            background-repeat: no-repeat;
            background-position: calc(100% - 8px) center;
        }
    }

    .has-error {
        label {
            color: inherit !important;
        }

        input,
        textarea {
            color: $red !important;
            border-color: $red !important;
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAQCAYAAADwMZRfAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYxIDY0LjE0MDk0OSwgMjAxMC8xMi8wNy0xMDo1NzowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNS4xIE1hY2ludG9zaCIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDozMTc4MDI1MTE0QTcxMUU0ODNEQUQ4QTlGNDJCOUUxRCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDozMTc4MDI1MjE0QTcxMUU0ODNEQUQ4QTlGNDJCOUUxRCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjMxNzgwMjRGMTRBNzExRTQ4M0RBRDhBOUY0MkI5RTFEIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjMxNzgwMjUwMTRBNzExRTQ4M0RBRDhBOUY0MkI5RTFEIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+RZWhIAAAAMhJREFUeNqUk8ENwjAMRZNsEG5wbEcII2QFGCErdIWuwArMwAj0zKldISvgSI4UGefTfulfXOvn2XLt53wxQA/yQL6Tc6/JgYAbOZEjeUYvWUCyMkXVSN6OkCQRYBCNRuKZwiv9V/KyhyQ1AZb9QjROoUBLjLxwGNIL8KinDRl4FCNqMuSnr13sU0Fd+MiiqJfaSZKoswIVskmSvMlBaa4Htip3k/l7dkwQOi8GpvQdmrmSaK8c0ejQ37lDuZJ4MM4/lX1tXwEGALO0JhQrJSaPAAAAAElFTkSuQmCC');
            box-shadow: none !important;

            &:focus + *,
            &:focus + * + *,
            &:focus + * + * + * {
                display: none !important;
            }
        }

        .help-block[data-fv-result="INVALID"] {
            @extend .tooltip;
            display: block !important;
            top: 100%;
            left: 50%;
            margin-top: 0;
            transform: translateX(-50%);
            background: #df4748;
            border-color: #d7191a;

            &::after {
                border-bottom-color: #df4748;
            }
        }
    }

    .has-success {
        input,
        textarea {
            border-color: $green;
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAYAAAB24g05AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYxIDY0LjE0MDk0OSwgMjAxMC8xMi8wNy0xMDo1NzowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNS4xIE1hY2ludG9zaCIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDozMTc4MDI1NTE0QTcxMUU0ODNEQUQ4QTlGNDJCOUUxRCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDozMTc4MDI1NjE0QTcxMUU0ODNEQUQ4QTlGNDJCOUUxRCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjMxNzgwMjUzMTRBNzExRTQ4M0RBRDhBOUY0MkI5RTFEIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjMxNzgwMjU0MTRBNzExRTQ4M0RBRDhBOUY0MkI5RTFEIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+dO3gsgAAAIRJREFUeNpibDuQxUACEATiVUC8GohnVdpPZWAhUfMZIFYCYhcov5OJDM0wUA4ikA0IhQkSofk9EIeBGCxIpnUgaaggQvMemAGCSJoZkFzRSUgzzAvvoYoZ0Ay5S0gzchhUYDFEkJBm9EDEZghezegGYDMEr2bkWEA3BATSCGkGAYAAAwD0ZySEm49pyAAAAABJRU5ErkJggg==');
        }
    }
}

;@import "sass-embedded-legacy-load-done:242";