//Styles for List items with special list styles
.a-list {
    &-item {
        padding: ($space * 2) 0;

        @include media(lg) {
            padding: 0 0 $space;
        }
    }

    &-item::before {
        content: "\25A0";
        color: $blue;
        display: inline-block;
        width: 1.5em;
        margin-left: -1.5em;

        @include media(lg) {
            width: 1.3em;
            margin-left: -1.3em;
        }
    }
}

;@import "sass-embedded-legacy-load-done:936";