.notification {
    background-color: $blue--light;
    border-radius: 2px;
    color: $grey--darker;
    display: flex;
    padding: $space * 3;
    margin-bottom: $space * 3;
    margin-top: 30px;
    @include border($color: $blue);

    h3 {
        font-weight: $font-weight--bold;
        margin-bottom: $space;
    }

    &__button {
        width: 100%;

        @include media(xl) {
            width: 188px;
        }

        &--deny {
            margin-top: $space * 2;

            @include media(xl) {
                margin-left: $space * 3;
                margin-top: 0;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:349";