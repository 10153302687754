//Refactors: styles/v1/components/_recommendations.scss

/* ------------------------------------ *\
    RECOMMENDATIONS
\* ------------------------------------ */

.o-product-recommendations {
    &--cart {
        margin-top: $space * 4;
        border: 0;
    }

    &--homepage {
        padding-bottom: $space;

        @include border($where: bottom);

        @include media(lg) {
            border-bottom: 0;
            padding-bottom: 0;
        }
    }

    &--show-add {
        padding-bottom: $space * 2;

        @include media(lg) {
            padding-bottom: 0;
        }

        @include border($where: bottom);

        @include media(lg) {
            border: 0;
        }

        .product-widget__image,
        .product-widget__data {
            width: 100%;
            padding-right: 0;
        }
    }

    &__placeholder {
        min-height: 499px;
        &:empty {
            align-items: center;
            display: flex;
            justify-content: center;
            &::after {
                animation: spin 0.5s infinite steps(9);
                color: $blue;
                content: $fa-var-cog;
                font-family: "FontAwesome";
                font-size: $font-size--large;
            }
        }
    }

    &.collapsed {
        display: none;

        @include media(lg) {
            display: flex;
        }
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    0% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}


;@import "sass-embedded-legacy-load-done:1248";