@include media(lg) {
    .o-usp-banner {
        flex-wrap: nowrap;
        justify-content: space-between;
        max-width: 90%;
        padding-top: $space * 8;
        padding-bottom: $space * 8;
        margin-bottom: $space * 14;

        &__item {
            border: none;
            width: auto;
            padding-left: unset;
            flex-wrap: wrap;
            padding: ($space * 6) ($space * 7);
        }

        &__icon {
            width: 100%;
            margin-bottom: 22px;
        }

        &__txt {
            width: 100%;
            margin-left: unset;
        }

        &__title {
            display: flex;
            justify-content: center;
        }

        &__subtitle {
            display: flex;
            justify-content: center;
        }
    }
}


;@import "sass-embedded-legacy-load-done:1300";