@include media(md) {
    .o-product-widget-large {
        &.-tile-view {
            grid-template-columns: unset;
        }

        &__image-container {
            &.-tile-view {
                justify-content: center;
                grid-row: unset;
                grid-column: unset;
                flex-direction: unset;
            }
        }

        &__image {
            &.-tile-view {
                aspect-ratio: 1 / 1;
                width: 100%;
                height: unset;
            }
        }

        &__badges {
            &.-tile-view {
                flex-direction: row;
                position: absolute;
                top: 0;
                justify-content: unset;
                gap: unset;
            }
        }

        &__product-details {
            &.-tile-view {
                grid-row: unset;
                grid-column: unset;
            }
        }

        &__product-name {
            &.-tile-view {
                display: -webkit-box;
                overflow: hidden;
                overflow-wrap: anywhere;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                height: 2 * $line-height--regular;
            }
        }

        &__rating {
            &.-hidden {
                display: block;
            }
        }

        &__delivery {
            &.-tile-view {
                grid-row: unset;
                grid-column: unset;
            }
        }

        &__price-tag {
            &.-tile-view {
                grid-row: unset;
                grid-column: unset;
            }
        }

        &__retail-price {
            &.-tile-view {
                height: 18px;
            }
        }

        &__variants {
            &.-tile-view {
                grid-row: unset;
                grid-column: unset;
            }

            &.-outer {
                grid-row: unset;
                grid-column: unset;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:1223";